.product-image {
	margin-left: -100px;
}

.product-image-enlarged {
	background: white;
	margin-left: -1120px !important;
}

@media screen and (max-width: 760px) {
	.product-image {
		margin-left: 0px;
	}
}

.size-list {
	list-style: none;

	&__item {
		font-size: 2.5rem;
		font-weight: bold;
		color: white;
		border: 2px solid white;
		margin: 20px 0;
		width: 80px;
		text-align: center;
		display: inline-block;
	}
}
