section {
	margin: 60px 0;
}

.product-item {
	&__image {
		background: #f18700;
		width: 40%;

		& img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&:hover {
		.product-item__image {
			background: #004863 !important;
		}

		box-shadow: 0 6px 8px #ccc;
		cursor: pointer;
	}
}

.get-in-touch {
	color: #dddddd;
	background: #232833;
	margin-bottom: 0px;

	& > div {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 80px 0px;

		& > img {
			width: 690px;
			height: 560px;
			object-fit: contain;
		}
	}

	&__contact {
		margin-left: 10px;

		& a {
			color: $primary-color;

			&::before {
				content: "";
				display: inline-block;
				border: .03125rem solid #e5231b;
				width: 8rem;
				height: .0625rem;
				position: absolute;
				left: 30.25rem;
				margin-top: 10px;

				@media (max-width: 560px) {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 560px) {
	.get-in-touch {
		width: 100%;
		& > div {
			flex-direction: column;

			& > img {
				width: 100%;
				height: 560px;
				object-fit: contain;
			}
		}
	}
}
