.primary-button {
	display: block;
	font-weight: 600;
	text-transform: uppercase;
	border: 0;
	background-color: $primary-color;
	color: white;
	width: 100%;
	padding: 10px;

	&:hover {
		transition: all .3s;
		color: white;
		background-color: #232833;
	}
}
