.card-img-top {
	height: 15rem;
}

.card:hover {
	cursor: pointer;

	& .card-img-top {
		background: #fafafa;
	}

	& .card-body h4 {
		color: $primary-color;
	}

	& {
		box-shadow: 0 4px 6px #eee;
		transform: scale(1.05);
		transition: all .4s;
	}
}

.card-link {
	text-decoration: none;
}
