.categories-list-group {
	&__item {
		font-weight: bold;

		&.filter-active {
			color: $primary-color;
		}

		&:hover {
			color: $primary-color;
			cursor: pointer;
		}

		& a:visited {
			color: $primary-color;
		}
	}
}

.pagination {
	display: flex;
	justify-content: center;

	& li {
		padding: 0px 10px;
	}

	& li.active a {
		color: $primary-color;
		text-decoration: underline;
	}
}