footer {
	color: #dddddd;
	background: #232833;
	padding: 50px;
}

.footer-links ul li a {
	color: white;
}

.footer-divider {
	height: 3px;
	background: white;
}

.social-icon {
	-webkit-filter: invert(1);
	filter: invert(1);
}