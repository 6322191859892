.chat-box {
	position: fixed;
	bottom: 0px;
	margin: 0;
	right: 15px;
	background: #fdfdf5;
	width: 300px;
	border-radius: 5px 5px 0px 0px;
	box-shadow: 0 4px 8px #ccc;

	&__head {
		text-align: center;
		cursor: pointer;
		background: $primary-color;
		padding: 15px;
		color: white;
		border-radius: 5px 5px 0px 0px;
	}

	&__body {
		display: none;
		padding: 15px;
		height: 200px;
		margin-bottom: 10px;

		& button {
			width: 100%;
			margin-top: 20px;
		}
	}
}
