.navbar {
	height: 100px;
	font-weight: 600;
	box-shadow: 0 2px 4px #eee;
}

.construction-headline {
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	background-color: $primary-color;
}

@media screen and (max-width: 960px) {
	.navbar {
		height: auto;
	}
}

.nav-item {
	position: relative;
	
	& a{
		font-weight: 700;
		font-size: 1.1rem;
	}

	&:hover {
		& .nav-dropdown {
			display: flex;
		}
	}

	& .nav-dropdown {
		display: none;
		flex-direction: column;
		// flex-wrap: wrap;
		z-index: 9;
		position: absolute;
		left: 0;
		top: 0;
		margin-top: 40px;
		border: 1px solid #eee;
		list-style: none;
		padding: 15px;
		padding-left: 20px;
		background: #fff;
		width: 250px;
		height: auto;
		
		& a {
			font-size: 1rem;	
			margin-bottom: 40px;
			font-family: 'Sansita Swashed', cursive;
			font-size: 1.2rem;
			font-weight: 600;

			&:hover {
				cursor: pointer;
				color: $primary-color;
			}
		}
	}
}

.bism {
	width: 45%;
	display: flex;
	justify-content: center;
	align-items: center;

	& img {
		width: 45% !important;
	}
}

@media (max-width: 560px) {
	.bism {
		display: block;
	}
}