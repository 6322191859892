@import './abstracts/variables';

@import './components/navbar';
@import './components/footer';
@import './components/card';
@import './components/react-slick';
@import './components/chatPopup';
@import './components/button';

@import './pages/home';
@import './pages/products';
@import './pages/detail';

.primary-color {
    color: $primary-color;
}